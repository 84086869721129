import { IconButton } from '@material-ui/core';
import { Block, ExpandMore, Person } from '@material-ui/icons';
import { Button, Typography } from '@mui/material';
import { format, parseISO } from "date-fns";
import React, { useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import NewContactTicketModal from '../NewContactTicketModal';

export default function ContactMessage({
  message,
  renderDailyTimestamps,
  renderNumberTicket,
  renderMessageDivider,
  renderQuotedMessage,
  classes,
  handleOpenMessageOptionsMenu,
  checkMessageMedia,
  index,
  isGroup,
}) {
  const contactData = useMemo(() => {
    // Formas que podem aparecer o nome salvo
    let savedName = ''

    if (message.body.includes('N:;')) {
      savedName = message.body.split('N:;')[1].split(';;;')[0].trim()
    } else if (message.body.includes('N:')) {
      savedName = message.body.split('\nN:')[1].split(';;')[0].trim()
    } else {
      savedName = 'Nome salvo não encontrado'
    }

    const phoneNumber = message.body.split('waid=')[1].split(':')[0].trim()
    const formatedPhoneNumber = message.body.split(';waid=')[1].split(':')[1].split('\n')[0].trim()

    // Formas que podem aparecer o nome do contato
    let contactName = ''

    if (message.body.includes('X-PHONETIC-FIRST-NAME:')) {
      contactName = message.body.split('X-PHONETIC-FIRST-NAME:')[1].split('\nTEL;')[0].trim()
    } else if (message.body.includes('FN:') && !message.body.includes('TITLE:')) { // Se não contem TITLE
      contactName = message.body.split('FN:')[1].split('\nTEL;')[0].trim()
    } else if (message.body.includes('FN:') && message.body.includes('TITLE:')) { // Se contem TITLE
      contactName = message.body.split('FN:')[1].split('\nORG:')[0].trim()
    } else {
      contactName = 'Nome não encontrado'
    }

    return {
      savedName,
      contactName,
      phoneNumber,
      formatedPhoneNumber,
    }
  }, [message])

  return (
    <React.Fragment key={message.id}>
      {renderDailyTimestamps(message, index)}
      {renderNumberTicket(message, index)}
      {renderMessageDivider(message, index)}
      <div className={classes.messageLeft}>
        <IconButton
          variant="contained"
          size="small"
          id="messageActionsButton"
          disabled={message.isDeleted}
          className={classes.messageActionsButton}
          onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
        >
          <ExpandMore />
        </IconButton>
        {isGroup && (
          <span className={classes.messageContactName}>
            {message.contact?.name}
          </span>
        )}

        {/* aviso de mensagem apagado pelo contato */}
        {message.isDeleted && (
          <div>
            <span className={"message-deleted"}
            >Essa mensagem foi apagada pelo contato &nbsp;
              <Block
                color="error"
                fontSize="small"
                className={classes.deletedIcon}
              />
            </span>
          </div>
        )}

        {(message.mediaUrl || message.mediaType === "locationMessage" || message.mediaType === "vcard"
          //|| message.mediaType === "multi_vcard" 
        ) && checkMessageMedia(message)}
        <div className={classes.textContentItem}>
          {message.quotedMsg && renderQuotedMessage(message)}

          {/* <MarkdownWrapper>{message.mediaType === "locationMessage" ? null : message.body}</MarkdownWrapper> */}
          <ContactCard
            message={message}
            contactData={contactData}
          />

          <span className={classes.timestamp}>
            {message.isEdited && <span>Editada </span>}
            {format(parseISO(message.createdAt), "HH:mm")}
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}

function ContactCard({ message, contactData }) {
  const history = useHistory()

  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <NewContactTicketModal
        modalOpen={open}
        initialNumber={contactData.phoneNumber}
        initialName={contactData.contactName}
        onClose={(ticket) => {
          history.push('/tickets/' + ticket.uuid)
          setOpen(false)
        }}
      />
      <div>
        <div style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '.6rem'
        }}>
          <div style={{
            backgroundColor: '#cccccc',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '.4rem',
          }}>
            <Person style={{
              fontSize: '2.7rem',
            }} />
          </div>
          <div style={{
            paddingTop: '.5rem'
          }}>
            <Typography variant='h5'>{contactData.contactName}</Typography>
            <Typography variant='body2'>{contactData.savedName}</Typography>
            <Typography variant='caption'>{contactData.formatedPhoneNumber}</Typography>
          </div>
        </div>
        <div style={{
          paddingTop: '.7rem',
          display: 'flex',
          alignItems: 'center',
          gap: '.3rem',
        }}>
          <Button onClick={() => setOpen(true)}>Criar ticket</Button>
          {/* <Button disabled>Salvar contato</Button> */}
        </div>
      </div>
    </React.Fragment>
  )
}