import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useParams } from "react-router-dom";

import logo from "../../assets/logo.png"; //PLW DESIGN LOGO//
import Ticket from "../../components/Ticket/";
import TicketsManager from "../../components/TicketsManagerTabs/";
import { PendingMessagesProvider } from "../../context/PendingMessages/PendingMessagesContext";

const useStyles = makeStyles(theme => ({
  chatContainer: {
    flex: 1,
    // backgroundColor: "#eee",
    padding: theme.spacing(1), //Aqui ele ajusta espaço na tela de ticket
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  messagesWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: theme.palette.boxticket, //DARK MODE PLW DESIGN//
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
  },
}));

const TicketsCustom = () => {
  const classes = useStyles();
  const { ticketId } = useParams();

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          <Grid item xs={4} className={classes.contactsWrapper}>
            <TicketsManager />
          </Grid>
          <Grid item xs={8} className={classes.messagesWrapper}>
            {ticketId ? (
              <>
                <PendingMessagesProvider>
                  <Ticket />
                </PendingMessagesProvider>
              </>
            ) : (
              <Paper square variant="outlined" className={classes.welcomeMsg}>
                {/* PLW DESIGN LOGO */}
                <div>
                  <center><img style={{ margin: "0 auto", width: "70%" }} src={logo} alt="logologin" /></center>
                </div>
                {/* PLW DESIGN LOGO */}
                {/*<span>{i18n.t("chat.noTicketMessage")}</span>*/}
              </Paper>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TicketsCustom;
