import React, { createContext, useCallback, useState } from 'react';

export const PendingMessagesContext = createContext();

export const PendingMessagesProvider = ({ children }) => {
  const [pendingMessages, setPendingMessages] = useState([]);
  const [messageTimers, setMessageTimers] = useState({}); // Para controlar os timers de cada mensagem

  const addPendingMessage = (message) => {
    setPendingMessages(prev => [...prev, message]);
  };

  const removePendingMessage = useCallback((tempId) => {
    // Primeiro verifica se a mensagem ainda existe
    setPendingMessages(prev => {
      const messageExists = prev.find(msg => msg.tempId === tempId);
      if (!messageExists) return prev;

      // Se existir, remove
      return prev.filter(msg => msg.tempId !== tempId);
    });
  }, []);

  const scheduleMessageRemoval = useCallback((tempId, delay = 2000) => {
    // Cancela timer anterior se existir
    if (messageTimers[tempId]) {
      clearTimeout(messageTimers[tempId]);
    }

    // Cria novo timer
    const timerId = setTimeout(() => {
      removePendingMessage(tempId);
      // Limpa o timer do objeto após a remoção
      setMessageTimers(prev => {
        const newTimers = { ...prev };
        delete newTimers[tempId];
        return newTimers;
      });
    }, delay);

    // Salva o timer
    setMessageTimers(prev => ({
      ...prev,
      [tempId]: timerId
    }));
  }, [messageTimers, removePendingMessage]);

  const resetPendingMessages = () => {
    setPendingMessages([]);
    setMessageTimers({});
  };

  return (
    <PendingMessagesContext.Provider
      value={{
        pendingMessages,
        addPendingMessage,
        removePendingMessage,
        scheduleMessageRemoval,
        resetPendingMessages
      }}
    >
      {children}
    </PendingMessagesContext.Provider>
  );
}; 